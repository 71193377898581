.ui.vertical.sidebar.menu {
  > .item {
    border-top: solid 1px #ae3335;
  }
}

.ui.top.sidebar.mobilemenu {
  background-color: #000000;
}

.sidebar .servicelink {
  border-top: 1px solid #ae3335;

  a {
    color: #f5c2c2;
  }
}
